<template>
  <Layout
    v-loading="form.loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="配置状态">
        <el-switch
          v-model="form.isOpen"
          :active-value="true"
          :inactive-value="false"
          @change="updateStatus()"
        />
      </el-form-item>
      <el-form-item label="版本号">
        <el-input
          v-model="form.versions"
          placeholder="审核版本号,多个用 &quot;,&quot; 分隔"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import {
  getVrAuditVersion,
  setVrAuditVersion,
  updateVrAuditVersion
} from '@/api/activity.js'
export default {
  name: 'VrAudtVersionConfig',

  data () {
    return {
      form: {
        loading: true,
        isOpen: false,
        versions: ''
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.form.loading = true
      getVrAuditVersion()
        .then(res => {
          if (res.code === 200) {
            this.form.isOpen = res.data.isOpen
            if (res.data.versionList.length > 0) {
              this.form.versions = (res.data.versionList).join(',')
            }
          }
        })
        .finally(() => {
          this.form.loading = false
        })
    },
    onSubmit () {
      this.form.loading = true
      var versions = (this.form.versions).split(',')
      setVrAuditVersion({ versions: versions })
        .then(res => {
          if (res.code !== 200 || !res.data.success) {
            this.$message.error('修改失败')
          }
        })
        .finally(() => {
          this.form.loading = false
          this.getData()
        })
    },
    updateStatus () {
      this.form.loading = true
      updateVrAuditVersion({ status: this.form.isOpen })
        .then(res => {
          if (res.code !== 200 || !res.data.success) {
            this.$message.error('修改失败')
          }
        })
        .finally(() => {
          this.form.loading = false
          this.getData()
        })
    }
  }
}
</script>
